
import { useEffect, useRef, useState } from "react";
import { blogService } from "../../services/blog-service";
import { Button, Card, Modal } from "flowbite-react";
import { Skeleton, message, Pagination } from 'antd';
import { BACKEND_URL } from "../../config/config";
import "../../styles/AllBlogsTable.css"
import { FaEdit, FaExclamationCircle, FaPlus, FaSearch } from "react-icons/fa";
import EditBlogModal from "./EditBlogModal";
import { MdDelete } from "react-icons/md";
import { MdOutlineReadMore } from "react-icons/md";
import AddBlogModal from "./AddBlogModal";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";



const AllBlogsTable = function () {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalBlogs, setTotalBlogs] = useState(0);
    const data_is_synced = useRef(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState({});
    const [selectedBlogId, setSelectedBlogId] = useState(0);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [pageSize, setPageSize] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userInfo = useSelector((state) => state.userInfo);


    const fetchAllBlogs = (page, search) => {
        console.log(page);
        setLoading(true);
        blogService.getAllBlogs(page, search)
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data?.articles);
                    setBlogs(response.data?.articles);
                    setTotalBlogs(response.data?.totalArticles);

                }
            }).catch(error => {
                toast.error("Une erreur s'est produite lors du chargement des blogs.");
            }).finally(() => {
                setLoading(false);
            })
    };

    const deleteBlogById = async (id) => {
        try {
            const response = await blogService.deleteBlogById(Number(id));
            if (response?.status === 200) {
                const updatedBlog = blogs.filter((blog) => blog.id !== id);
                setBlogs(updatedBlog);
                if (updatedBlog.length <= 6) {
                    const totalPagesAfterDelete = Math.ceil((totalBlogs - 1) / 6);
                    if (currentPage >= totalPagesAfterDelete) {
                        handlePageChange(totalPagesAfterDelete)
                    } else {
                        handlePageChange(currentPage)
                    }
                }
                toast.success(response?.message)
            }

        } catch (error) {
            message.error("Failed to fetch invoice details")
        }
    }

    useEffect(() => {
        if (!data_is_synced.current) {
            fetchAllBlogs(currentPage, searchTerm);
            data_is_synced.current = true;
        }
    }, [currentPage, searchTerm])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchAllBlogs(page, searchTerm)
    };

    const handleShowEditModal = (blog) => {
        setSelectedBlog(blog)
        setShowEditModal(!showEditModal)
    }
    const handleSearch = () => {
        console.log(currentPage);
        const totalPages = Math.ceil(totalBlogs / pageSize);
        if (currentPage > totalPages) {
            setCurrentPage(totalPages);
        }
        fetchAllBlogs(1, searchTerm);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const updateBlogs = () => {
        fetchAllBlogs(currentPage, searchTerm);
    };

    const handleDeleteBlog = () => {
        deleteBlogById(selectedBlogId)
        setShowDeleteModal(false);
    }

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <Link to="#" className='dark:text-white dark:bg-gray-400 bg-gray-300 p-2 rounded-lg text-black'>Précédent</Link>;
        }
        if (type === 'next') {
            return <Link to="#" className='dark:text-white dark:bg-gray-400 bg-gray-300 p-2 rounded-lg text-black'>Suivant</Link>;
        }
        return originalElement;
    }

    return (
        <>
            {loading && <Skeleton active />}
            {!loading && (
                <div className="">
                    <div className="mx-2 my-1 flex h-7">
                        <input
                            type="text"
                            id="sarchInput"
                            className="block rounded-lg rounded-r-none border border-gray-300 bg-gray-200 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            value={searchTerm}
                            onChange={(event) => {
                                setSearchTerm(event.target.value)
                            }}
                            onKeyDown={handleKeyDown}
                        />

                        <span
                            className="mr-2 cursor-pointer rounded-lg rounded-l-none bg-gray-200 text-lg dark:bg-gray-700 dark:text-white"
                            onClick={handleSearch}
                        >
                            <FaSearch size={25} className="p-1  text-primary-400" />
                        </span>
                        <span
                            className="cursor-pointer rounded-lg bg-gray-200 text-lg dark:bg-gray-700 dark:text-white"
                            onClick={() => { setShowAddModal(true) }}
                        >
                            <FaPlus size={25} className="p-1  text-primary-400" />
                        </span>
                    </div>
                    {blogs.map(blog => (
                        <Card className="w-full my-1" key={blog.id}>
                            <div className="flex w-full">
                                <div className="max-w-80 mx-2">
                                    <img src={BACKEND_URL + blog.imgs} alt="blogImg" />
                                </div>
                                <div className="flex flex-col h-auto w-full p-2">
                                    <h5 className="text-xl font-bold font-serif tracking-tight dark:text-white">
                                        {blog.id + " - " + blog.title}
                                    </h5>
                                    <p className="font-serif text-gray-700 dark:text-white">
                                        {blog.description}
                                    </p>
                                    <div className="flex gap-3 self-end mt-auto">
                                        <span
                                            className="cursor-pointer rounded bg-indigo-200 p-1 text-lg text-blue-500 hover:text-blue-700"
                                            onClick={() => {
                                                return navigate(`/blogs/details/${blog.id}`)
                                            }}
                                        >
                                            <MdOutlineReadMore size={'1.3rem'} />
                                        </span>
                                        {
                                            ['admin', 'blogger'].includes(userInfo.role.toLowerCase()) && (
                                                <>
                                                    <span
                                                        className="cursor-pointer rounded bg-indigo-200 p-1 text-lg text-green-500 hover:text-green-700"
                                                        onClick={() => handleShowEditModal(blog)}
                                                    >
                                                        <FaEdit size={'1.3rem'} />
                                                    </span>
                                                    <span
                                                        className="cursor-pointer rounded bg-indigo-200 p-1 text-lg text-red-500 hover:text-red-700"
                                                        onClick={() => {
                                                            setSelectedBlogId(blog.id);
                                                            setShowDeleteModal(true);
                                                        }}
                                                    >
                                                        <MdDelete size={'1.3rem'} />
                                                    </span>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))}
                    <div className="mx-2 flex py-2">
                        <Pagination
                            total={totalBlogs}
                            pageSize={pageSize}
                            onChange={(page, size) => {
                                handlePageChange(page);
                                setPageSize(size);
                            }}
                            className="dark:text-white"
                            itemRender={itemRender}
                            current={currentPage}
                            responsive
                        />
                    </div>
                    <AddBlogModal isOpen={showAddModal} setOpen={setShowAddModal} updateBlogs={updateBlogs} />
                    <EditBlogModal blog={selectedBlog} isOpen={showEditModal} setOpen={setShowEditModal} updateBlogs={updateBlogs} />
                    <Modal show={showDeleteModal} size="md" onClose={() => setShowDeleteModal(false)} popup>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <FaExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    {t('deletionAlertMsg')}
                                </h3>
                                <div className="flex justify-center gap-4">
                                    <Button color="failure" onClick={handleDeleteBlog}>
                                        {"Confirmer"}
                                    </Button>
                                    <Button color="gray" onClick={() => setShowDeleteModal(false)}>
                                        {t('cancel')}
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={openModal} onClose={() => setOpenModal(false)}>
                        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">{selectedBlog?.title}</Modal.Header>
                        <Modal.Body>
                            <Card
                                imgSrc={BACKEND_URL + selectedBlog?.imgs}
                                imgAlt="image"
                                horizontal
                                className="max-w-sm">
                                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    {selectedBlog?.description}
                                </h5>
                                <p className="font-normal text-gray-700 dark:text-gray-400">
                                    {selectedBlog?.body}
                                </p>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={() => setOpenModal(false)}
                                type="submit"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                {t('quit')}
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default AllBlogsTable