import React, { Fragment, hasOwnProperty, toLocaleString } from 'react'
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import logo from "../../../assets/images/Color_logo.png"
import { useTranslation } from 'react-i18next';

export const styles = StyleSheet.create({
    page: { fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column' },

    spaceBetween: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: "#3E3E3E" },

    titleContainer: { flexDirection: 'row', marginTop: 24 },

    logo: { width: 90 },

    reportTitle: { fontSize: 16, textAlign: 'center' },

    addressTitle: { fontSize: 11, fontStyle: 'bold' },

    invoice: { fontWeight: 'bold', fontSize: 20 },

    invoiceNumber: { fontSize: 11, fontWeight: 'bold' },

    address: { fontWeight: 400, fontSize: 10 },

    theader: {
        marginTop: 20,
        fontSize: 10,
        fontStyle: 'bold',
        paddingTop: 4,
        paddingLeft: 7,
        flex: 1,
        height: 20,
        backgroundColor: '#6B7280',
        borderColor: 'whitesmoke',
        borderRightWidth: 1,
        borderBottomWidth: 1,
    },

    theader2: {
        flex: 2,
        borderRightWidth: 0,
        borderBottomWidth: 1,
    },

    tbody: {
        fontSize: 9,
        paddingTop: 4,
        paddingLeft: 7,
        flex: 1,
        borderColor: 'whitesmoke',
        borderRightWidth: 1,
        borderBottomWidth: 1,
        backgroundColor: '#DEDEDE'
    },

    total: {
        fontSize: 9,
        paddingTop: 4,
        paddingLeft: 7,
        flex: 1.5,
        borderColor: 'whitesmoke',
        borderBottomWidth: 1
    },

    tbody2: {
        flex: 2,
        borderRightWidth: 1,
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 40,
        right: 40,
        fontSize: 8,
        textAlign: 'center',
        color: '#3E3E3E',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#DEDEDE',
        padding: '10px',
    }

});

const InvoiceCompany = ({ invoice }) => {
    const { t } = useTranslation();

    const propertiesToExtract = [
        "pagesNumber",
        "calandrierPrice",
        "redactionContenu",
        "newsLetter",
        "plateformeEcommerce",
        "gestionCompte",
        "nmbrPageBelingue",
        "ameliorationSEO",
        "analyseStatistique",
        "hebergementMessagerie",
        "formationGestion",
        "assistanceTech",
        "abonnementSEO",
        "renouvelementContenu",
    ];

    const invoiceArray = [];
    propertiesToExtract.forEach(prop => {
        if (invoice.hasOwnProperty(prop) && invoice[prop] !== null) {
            if (prop === "pagesNumber") {
                invoiceArray.push({ desc: t('pagesNumber'), price: invoice[prop] * 740 })
            } else {
                invoiceArray.push({ desc: t(prop), price: invoice[prop] })
            }

        }
    })

    const Address = () => {
        return (
            <View style={styles.titleContainer}>
                <View style={styles.spaceBetween}>
                    <View>
                        <Text style={styles.invoice}>{t('demanded')} </Text>
                        <Text style={styles.invoiceNumber}>{t('invoiceNumber')}: {invoice.id} </Text>
                        <Text style={styles.address}>{t('created_at')} : 
                            {
                                new Date(invoice.created_at).toLocaleString('fr-FR', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                })
                            }</Text>
                    </View>
                </View>
            </View>
        )
    };

    const InvoiceTitle = () => {
        return (
            <View style={styles.titleContainer}>
                <View style={styles.spaceBetween}>
                    <Image style={styles.logo} src={logo} />
                </View>
            </View>
        )
    };


    const UserAddress = () => {
        return (
            <View style={styles.titleContainer}>
                <View style={styles.spaceBetween}>
                    <View style={{ maxWidth: "100%" }}>
                        <Text style={styles.address}>
                            {invoice.gender !== null && invoice.gender.trim() !== "" ? t('gender') + " :" + invoice.gender : ""}
                        </Text>
                        <Text style={styles.address}>
                            {t('firstName')} : {invoice.firstname}
                        </Text>
                        <Text style={styles.address}>
                            {t('lastName')} : {invoice.lastname}
                        </Text>
                        <Text style={styles.address}>
                            {t('company')} : {invoice.company_name}
                        </Text>
                        <Text style={styles.address}>
                            {t('vatNo')} : {invoice.tvaIce}
                        </Text>
                        <Text style={styles.address}>
                            {t('email')} : {invoice.email}
                        </Text>
                        <Text style={styles.address}>
                            {t('telephone')} : {invoice.phone_number}
                        </Text>
                        <Text style={styles.address}>
                            {t('adresse')} : {invoice.adresse}
                        </Text>
                        <Text style={styles.address}>
                            NPA : {invoice.npa}
                        </Text>
                        <Text style={styles.address}>
                            {t('city')} : {invoice.ville}
                        </Text>
                        <Text style={styles.address}>
                            {t('country')} : {invoice.country}
                        </Text>
                        <Text style={styles.address}>
                            {t('serviceTitle')} : {invoice.serviceTitle}
                        </Text>
                        <Text style={styles.address}>
                            Message : {invoice.msg}
                        </Text>
                        <Text style={styles.address}>
                            {t('attachement')} : {invoice.fileUpload !== null ? t('yes') : t('no')}
                        </Text>
                        <Text style={styles.address}>
                            {t('finishedProcess')} : {invoice.is_finished === 1 ? t('yes') : t('no')}
                        </Text>
                        <Text style={styles.address}>
                            {invoice.is_finished === 1 ? `${t('pageNumber')} : ${invoice.pagesNumber}` : ""}
                        </Text>
                    </View>

                </View>
            </View>
        )
    };

    const TableHead = () => {
        return (
            <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
                <View style={[styles.theader, styles.theader2]}>
                    <Text >Description</Text>
                </View>
                <View style={styles.theader}>
                    <Text>{t('price')}</Text>
                </View>
            </View>
        )
    };

    const TableBody = () => {
        return (
            invoiceArray.map((invoice) => (
                <Fragment key={invoice.id}>
                    <View style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={[styles.tbody, styles.tbody2]}>
                            <Text >{invoice.desc}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{invoice.price} </Text>
                        </View>
                    </View>
                </Fragment>
            ))
        )
    };

    const TableTotal = () => {
        return (
            <View style={{ width: '100%', flexDirection: 'row', marginTop: '5px' }}>
                <View style={styles.total}>
                    <Text></Text>
                </View>
                <View style={styles.total}>
                    <Text> </Text>
                </View>
                <View style={styles.tbody}>
                    <Text>{t('totalPriceIndicatif')}</Text>
                </View>
                <View style={styles.tbody}>
                    <Text>
                        {invoice.totalPrice}
                    </Text>
                </View>
            </View>
        )
    };

    // const Footer = () => (
    //     <View style={styles.footer}>
    //         <Text></Text>
    //         <Text>1</Text>
    //         <Text>{t('printedAt')} : {
    //             new Date().toLocaleString('fr-FR', {
    //                 year: 'numeric',
    //                 month: '2-digit',
    //                 day: '2-digit',
    //                 hour: '2-digit',
    //                 minute: '2-digit',
    //                 second: '2-digit'
    //             })}
    //         </Text>
    //     </View>
    // );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle />
                <Address />
                <UserAddress />
                {
                    invoiceArray.length !== 0 && (
                        <>
                            <TableHead />
                            <TableBody />
                        </>
                    )
                }
                {
                    invoice.is_finished === 1 && <TableTotal />
                }
                {/* <Footer /> */}
            </Page>
        </Document>
    )
}
export default InvoiceCompany