import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MainLayout from './layouts/MainLayout';
import ProtectedRoute from './helpers/ProtectedRoute';
import DashboardPage from './pages';
import Blog from './pages/blog/Blog';
import UserProfile from './pages/user/UserProfile';
import SignInPage from './pages/authentication/sign-in';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './pages/404/NotFound';
import BlogDetails from './pages/blog/BlogDetails';
import Users from './pages/user/Users';
import ParticularInvoice from './pages/invoices/particular-invoice';
import CompanyInvoice from './pages/invoices/company-invoice';

function App() {
  // const userInfo = useSelector((state) => state.userInfo);


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route
              path="/"
              element={
                <ProtectedRoute
                  element={<DashboardPage />}
                  roles={['admin', 'manager', 'blogger']}
                />
              }
            />
            <Route
              path="particular-demand"
              element={
                <ProtectedRoute
                  element={<ParticularInvoice />}
                  roles={['admin', 'manager']}
                />
              }
            />
            <Route
              path="company-demand"
              element={
                <ProtectedRoute
                  element={<CompanyInvoice />}
                  roles={['admin', 'manager']}
                />
              }
            />
            <Route
              path="user"
              element={
                <ProtectedRoute
                  element={<UserProfile />}
                  roles={['admin', 'manager','blogger']}
                />
              }
            />
            <Route
              path="users"
              element={
                <ProtectedRoute
                  element={<Users />}
                  roles={['admin', 'manager']}
                />
              }
            />
            <Route
              path="blogs"
              element={
                <ProtectedRoute
                  element={<Blog />}
                  roles={['admin', 'blogger']}
                />
              }
            />
            <Route
              path="blogs/details/:id"
              element={
                <ProtectedRoute
                  element={<BlogDetails />}
                  roles={['admin', 'blogger']}
                />
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route path="/authentication/sign-in" element={<SignInPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
