import { Badge, Breadcrumb, Button, Modal } from 'flowbite-react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoHomeOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { UserService } from '../../services/user-service'
import { toast } from 'react-toastify'
import { Pagination, Skeleton } from 'antd'
import { FaTrashAlt } from 'react-icons/fa'
import { IoIosAlert } from "react-icons/io";
import { useSelector } from 'react-redux'

const Users = () => {
    const { t } = useTranslation();
    const data_is_synced = useRef(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalUsers, setTotalUsers] = useState(0);
    const [pageSize, setPageSize] = useState(6);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [showDeletionConfirmModal, setShowDeletionConfimModal] = useState(false);
    const userInfo = useSelector((state) => state.userInfo);

    const columns = [
        { title: t('firstName'), dataIndex: 'firstname', key: 'firstname' },
        { title: t('lastName'), dataIndex: 'lastname', key: 'lastname' },
        { title: t('email'), dataIndex: 'email', key: 'email' },
        { title: t('role'), dataIndex: 'role', key: 'role' },
        { title: t('created_at'), dataIndex: 'created_at', key: 'created_at' },
        userInfo.role === "ADMIN" && { title: "Actions", key: 'actions' },
    ];

    const fetchUsersByPage = async (page) => {
        setLoading(true);
        UserService.getAllUsers(page)
            .then(response => {
                if (response.status === 200) {
                    setUsers(response.data?.users);
                    setTotalUsers(response.data?.totalUsers);
                }
            }).catch(error => {
                toast.error("Une erreur s'est produite lors du chargement des users.");
            }).finally(() => {
                setLoading(false);
            })
    }
    useEffect(() => {
        if (!data_is_synced.current) {
            fetchUsersByPage(currentPage);
            data_is_synced.current = true;
        }
    }, [currentPage])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchUsersByPage(page)
    };

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <Link to="#" className='dark:text-white dark:bg-gray-400 bg-gray-300 p-2 rounded-lg text-black'>{t('previeus')}</Link>;
        }
        if (type === 'next') {
            return <Link to="#" className='dark:text-white dark:bg-gray-400 bg-gray-300 p-2 rounded-lg text-black'>{t('next')}</Link>;
        }
        return originalElement;
    }

    const handleDeleteUser = () => {
        console.log(selectedUserId);
        UserService.deleteUser(selectedUserId)
            .then(response => {
                console.log(response);
                if (response?.status === 200) {
                    const updatedUsers = users.filter(user => user.id !== selectedUserId);
                    setUsers(updatedUsers);
                    if (updatedUsers.length <= 6) {
                        const totalPagesAfterDelete = Math.ceil((totalUsers - 1) / 6);
                        if (currentPage >= totalPagesAfterDelete) {
                            handlePageChange(totalPagesAfterDelete);
                        } else {
                            handlePageChange(currentPage);
                        }
                    }
                    toast.success(response?.message);
                    setShowDeletionConfimModal(false);
                }
            })
    }
    return (
        <>
            {loading && <Skeleton />}
            {!loading && (
                <div>
                    <div
                        className="border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                        <Breadcrumb>
                            <Breadcrumb.Item >
                                <div className="flex items-center gap-x-3">
                                    <IoHomeOutline className="text-base" />
                                    <span className="dark:text-white">
                                        <Link to="/">{t('dashboard')}</Link>
                                    </span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item >
                                <Link to="/users">{t('users')}</Link>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="relative overflow-x-auto">
                        <table className="w-full my-2 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    {columns.map((column) => (
                                        <th
                                            key={column.key}
                                            scope="col"
                                            className={`px-6 py-3 cursor-pointer }`}
                                        >
                                            {column.title}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((user) => (
                                        <tr key={user.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {user.firstname}
                                            </th>
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {user.lastname}
                                            </th>
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {user.email}
                                            </th>
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {
                                                    user.role === "ADMIN" ?
                                                        <Badge color="success">{user.role}</Badge>
                                                        :
                                                        <Badge color="failure">{user.role}</Badge>
                                                }
                                            </th>
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {user.created_at}
                                            </th>
                                            {
                                                userInfo.role === "ADMIN" && <th scope="row" className="px-6 py-4 font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                                                    <span
                                                        key={user.id}
                                                        className='cursor-pointer'
                                                        onClick={() => {
                                                            setSelectedUserId(user.id);
                                                            setShowDeletionConfimModal(true);
                                                        }}
                                                    >
                                                        <FaTrashAlt className="text-red-500" />
                                                    </span>
                                                </th>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="sm:justify-center">
                        <Pagination
                            total={totalUsers}
                            pageSize={pageSize}
                            onChange={(page, size) => {
                                handlePageChange(page);
                                setPageSize(size);
                            }}
                            className="dark:text-white"
                            itemRender={itemRender}
                            current={currentPage}
                        />
                    </div>
                    <Modal onClose={() => setShowDeletionConfimModal(false)} show={showDeletionConfirmModal} size="md">
                        <Modal.Header className="px-6 pt-6 pb-0">
                            <span className="sr-only">{t('deleteUser')}</span>
                        </Modal.Header>
                        <Modal.Body className="px-6 pt-0 pb-6">
                            <div className="flex flex-col items-center gap-y-6 text-center">
                                <IoIosAlert className="text-7xl text-red-500" />

                                <p className="text-xl text-gray-500">
                                    {t('deleteUserMsg')}
                                </p>
                                <div className="flex items-center gap-x-3">
                                    <Button color="failure" onClick={handleDeleteUser}>
                                        {t('deleteUserConfirm')}
                                    </Button>
                                    <Button color="gray" onClick={() => setShowDeletionConfimModal(false)}>
                                        {t('deleteUserCancel')}
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </>
    )
}

export default Users