import { toast } from "react-toastify";
import { BACKEND_URL } from "../config/config"
import axiosService from "./interceptor-service";


const getTotalBlogs = async () => {
    try {
        const apiUrl = `${BACKEND_URL}/api/dashboard/blogs`;
        return await axiosService.get(apiUrl);
    } catch (error) {
        toast.error("Error survenu lors du chargement de total des blogs");
    }
}

const getTotalInvoices = async () => {
    try {
        const apiUrl = `${BACKEND_URL}/api/dashboard/invoices`;
        return await axiosService.get(apiUrl);
    } catch (error) {
        toast.error("Error survenu lors du chargement de total des invoices");
    }
}

const getTotalUsers = async () => {
    try {
        const apiUrl = `${BACKEND_URL}/api/dashboard/users`;
        return await axiosService.get(apiUrl);
    } catch (error) {
        toast.error("Error survenu lors du chargement de total des users");
    }
}

const getTotalInvoicesParticular = async () => {
    try {
        const apiUrl = `${BACKEND_URL}/api/dashboard/invoices-particular`;
        return await axiosService.get(apiUrl);
    } catch (error) {
        toast.error("Error survenu lors du chargement de total des users");
    }
}
const getTotalInvoicesCompany = async () => {
    try {
        const apiUrl = `${BACKEND_URL}/api/dashboard/invoices-company`;
        return await axiosService.get(apiUrl);
    } catch (error) {
        toast.error("Error survenu lors du chargement de total des users");
    }
}

const getGoogleAnalyticsByCity = async () => {
    try {
        const apiUrl = `${BACKEND_URL}/api/dashboard/analytics-city`;
        return await axiosService.get(apiUrl);
    } catch (error) {
        toast.error("Error survenu lors du chargement de total des users");
    }
}

const getGoogleAnalyticsByCountry = async () => {
    try {
        const apiUrl = `${BACKEND_URL}/api/dashboard/analytics-country`;
        return await axiosService.get(apiUrl);
    } catch (error) {
        toast.error("Error survenu lors du chargement de total des users");
    }
}


export const dashboardService = {
    getTotalBlogs,
    getTotalInvoices,
    getTotalUsers,
    getTotalInvoicesParticular,
    getTotalInvoicesCompany,
    getGoogleAnalyticsByCity,
    getGoogleAnalyticsByCountry
}