/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import { Modal, theme } from "flowbite-react";
import { useForm } from "react-hook-form";
import axiosService from "../../services/interceptor-service";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../config/config";
import { useTranslation } from "react-i18next";
import React from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useRef, useState } from "react";
import ReactQuill from "react-quill";

const EditBlogModal = function ({ isOpen, setOpen, blog, updateBlogs }) {
    const { register, handleSubmit, formState: { errors, isValid }, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            title: blog.title,
            description: blog.description,
            publicationDate: blog.publication_date
        }
    });
    const { t } = useTranslation()
    const editorRef = useRef();
    const [image, setImage] = useState("");
    const inputImageRef = useRef(null);
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const datePublishedRef = useRef(null);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);

    const handleInputChange = () => {
        const newErrors = {};
        if (titleRef.current.value === "") {
            newErrors.title = t('titleErrMsg');
        }
        if (descriptionRef.current.value === "") {
            newErrors.description = t('descriptionMsg');
        }
        if (datePublishedRef.current.value === "") {
            newErrors.publicationDate = t('publihsDateErrMsg');
        }
        setFormErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }

    }


    const onFormSubmit = (data) => {
        const formData = new FormData();
        console.log(data);
        formData.append("title", titleRef.current.value);
        formData.append("description", descriptionRef.current.value);
        formData.append("body", editorRef.current.value);
        formData.append("publicationDate", datePublishedRef.current.value);
        formData.append("image", image);
        axiosService.put(`${BACKEND_URL}/api/blog/article/${blog.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(responseData => {
                if (responseData?.status === 200) {
                    toast.success(responseData?.message);
                    setOpen(false);
                    updateBlogs();
                }
            })
            .catch(error => console.log(error));
    }

    const handleImageChange = (e) => {
        const files = e.target.files;
        console.log(files);
        setImage(files[0]);
    };
    const handleImageClick = () => {
        inputImageRef.current.click();
    }
    return (
        <Modal onClose={() => setOpen(false)} show={isOpen}>
            <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                <strong>Modifier</strong>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-col">
                            <label
                                htmlFor="title"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                {t('title')}
                            </label>
                            <input
                                defaultValue={blog.title}
                                name="title"
                                type="text"
                                id="title"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-300 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-gray-700"
                                /* {...register("title", { required: t('titleErrMsg') })} */
                                ref={titleRef}
                                required
                            />
                            {errors.title && <span className="text-red-500 text-sm">{errors.title}</span>} 
                        </div>
                        <div className="flex flex-col">
                            <label
                                htmlFor="description"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                {t('description')}
                            </label>
                            <textarea
                                defaultValue={blog.description}
                                name="description"
                                type="text"
                                id="description"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-300 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-700 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                /* {...register("description", { required: t('descriptionMsg') })} */
                                ref={descriptionRef}
                                required
                            />
                            {/* {errors.description && <span className="text-red-500 text-sm">{errors.description.message}</span>} */}
                        </div>
                        <div className="flex flex-col">
                            <label
                                htmlFor="body"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                {t('content')}
                            </label>
                            <ReactQuill
                                className="dark:text-gray-700 dark:bg-gray-300"
                                theme="snow"
                                defaultValue={blog.body}
                                ref={editorRef}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label
                                htmlFor="image"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                {t('image')}
                            </label>
                            <div onClick={handleImageClick} className="cursor-pointer border-dashed border-4 rounded-3xl border-gray-400">
                                {
                                    image ?
                                        <img
                                            src={URL.createObjectURL(image)}
                                            alt="Blog Image Preview"
                                            className="rounded-3xl"
                                            width={1200}
                                            height={50}
                                        /> :
                                        <img
                                            src={BACKEND_URL + blog.imgs}
                                            alt="Blog Image Preview"
                                            className="rounded-3xl"
                                            width={1200}
                                            height={50}
                                        />
                                }
                                <input
                                    ref={inputImageRef}
                                    id="image"
                                    type="file"
                                    className="hidden"
                                    name="image"
                                    onChange={handleImageChange}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <label
                                htmlFor="publicationDate"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                {t('publicationDate')}
                            </label>
                            <input
                                defaultValue={blog.publication_date}
                                name="publicationDate"
                                type="datetime-local"
                                id="publicationDate"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                /* {...register("publicationDate")} */
                                ref={datePublishedRef}
                                required
                            />
                        </div>
                        <div className="flex flex-col">
                            <button
                                /* disabled={!isValid} */
                                type="submit"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                {t('save')}
                            </button>
                        </div>
                    </div>

                </form>
            </Modal.Body>
        </Modal>
    );
};


export default EditBlogModal;