import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { useTranslation } from 'react-i18next';

const ChartComponent = ({ data }) => {
    // const { t } = useTranslation();

    // Map the data into the format expected by recharts
    const chartData = data.map(item => ({
        city: item.city,
        value: Number(item.value)
    }));

    // Find the maximum value for dynamic tick generation
    const maxValue = Math.max(...chartData.map(item => item.value));
    const tickCount = Math.ceil(maxValue / 10); // Adjust tick count based on maxValue

    return (
        <div className="relative w-full h-full dark:bg-gray-700 bg-gray-400 p-4 rounded-lg shadow-lg">
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={chartData}
                    margin={{ top: 20, right: 30, bottom: 20, left: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="city" tick={{ angle: -45, textAnchor: 'end' }} />
                    <YAxis
                        tickFormatter={(value) => value}
                        ticks={Array.from({ length: tickCount + 1 }, (_, i) => i * 10)} // Generate ticks dynamically
                    />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ChartComponent;
