import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Avatar, Dropdown } from 'antd';
import { FaKey, FaRegUser, FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { userLogout } from '../redux/auth/authActions';
import { toast } from 'react-toastify';
import { IoIosPersonAdd } from "react-icons/io";
import 'flag-icon-css/css/flag-icon.min.css';
import { useTranslation } from 'react-i18next';
import ChangePassword from '../pages/user/ChangePassword';
import InviteUsers from '../pages/user/InviteUsers';


const HeaderMain = () => {
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [selectedLanguage, setSelectedLanguage] = useState("fr");
    const [t, i18n] = useTranslation();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showInviteUserdModal, setShowInviteUserdModal] = useState(false);

    const handleSignOut = async () => {
        try {
            const result = await dispatch(userLogout());
            console.log(result);
            if (result.payload && result.payload.status === 200) {
                toast.success(result.payload.message);
                navigate("/authentication/sign-in")
            } else {
                toast.error('An error occurred during logout.');
            }

        } catch (error) {
            console.error('Error logging out:', error);
            toast.error("Error occurred while logging out the user");
        }
    };


    const handleOpenChangePasswordModal = () => {
        setShowChangePasswordModal(true);
    };
    const handleOpenInviteUserModal = () => {
        setShowInviteUserdModal(true);
    };

    // eslint-disable-next-line no-sparse-arrays
    const userItems = [
        {
            label: <Link to="/user" ><p className='dark:text-white'>{t('profile')}</p></Link>,
            icon: <CgProfile className='text-gray-800 dark:text-white' size={16} />,
            key: 'dashboard',
        },
        {
            label: <Link to="#" onClick={handleOpenChangePasswordModal}><p className='dark:text-white'>{t('changePassword')}</p></Link>,
            icon: <FaKey className='text-gray-800 dark:text-white' size={16} />,
            key: 'password'
        },
        ...(userInfo.role === "ADMIN" ? [{
            label: <Link to="#" onClick={handleOpenInviteUserModal}><p className='dark:text-white'>{t('inviteUser')}</p></Link>,
            icon: <IoIosPersonAdd className='text-gray-800 dark:text-white' size={16} />,
            key: 'inviteUser'
        }] : []),
        , {
            label: <Link to="#" onClick={handleSignOut}><p className='dark:text-white'>{t('logout')}</p></Link>,
            icon: <FaSignOutAlt className='text-gray-800 dark:text-white' size={16} />,
            key: 'deconnection'
        }
    ]

    const handleLanguageChange = ({ key }) => {
        setSelectedLanguage(key);
        i18n.changeLanguage(key);
    };


    const lanItems = [
        { label: 'fr', flag: 'flag-icon-fr', key: 'fr' },
        { label: 'gb', flag: 'flag-icon-gb', key: 'us' }
    ];

    const sortedItems = selectedLanguage === 'fr' ? lanItems : [...lanItems].reverse();

    const languageMenu = {
        items: sortedItems.map(item => ({
            key: item.key,
            label: (
                <span className={`flag-icon ${item.flag} mr-2 rounded-lg cursor-pointer size-4`} />
            )
        })),
        onClick: handleLanguageChange
    };

    return (
        <header className="relative flex h-16 w-full items-center justify-end space-x-10 bg-gray-100 px-5 dark:bg-gray-600">
            <div className="flex shrink-0 items-center space-x-4 text-white">
                <div className="relative text-left">
                    <Dropdown menu={languageMenu} trigger={['click']} placement="topLeft">
                        <span className={`flag-icon ${sortedItems[0].flag} mr-2 cursor-pointer rounded-lg size-4`} />
                    </Dropdown>
                </div>
                <div className="flex flex-col items-end ">
                    <div className="text-md font-medium text-gray-600 dark:text-white">{userInfo.email}</div>
                    <div className="font-regular text-sm text-gray-600 dark:text-white">{userInfo.role}</div>
                </div>

                <div className="h-10 w-10 cursor-pointer rounded-full border-2 dark:bg-gray-200">
                    <Dropdown menu={{ items: userItems, className: "dark:bg-gray-800" }} trigger={['click']}>
                        <Avatar
                            className='h-full w-full p-0'
                            size={"default"}
                            icon={<FaRegUser className="dark:text-white" />}
                        />
                    </Dropdown>
                </div>
            </div>
            <ChangePassword openModal={showChangePasswordModal} setOpenModalPassword={setShowChangePasswordModal} />
            <InviteUsers openModal={showInviteUserdModal} setOpenModalInviteUser={setShowInviteUserdModal} />
        </header >
    )
}

export default HeaderMain