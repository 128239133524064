import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Flowbite } from 'flowbite-react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from "./redux/store/store";
import { theme } from 'flowbite-react';
import './i18n';

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Flowbite theme={{ theme }}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback={<div>Loading ...</div>}>
            <App />
          </Suspense>
        </PersistGate>
      </Provider>
    </Flowbite>
  </StrictMode>,
)
