import { BACKEND_URL } from "../config/config";
import axiosService from "./interceptor-service";

const getAllUsers = async (page) => {
    try {
        let apiUrl = `${BACKEND_URL}/api/user/allUsers/${page}`
        const response = await axiosService.get(apiUrl);
        return response;
    } catch (error) {
        throw new Error(error.response.data.message || "Error fetching users");
    }
};

const changePassword = async (data) => {
    try {
        const response = await axiosService.post(`${BACKEND_URL}/api/user/changePassword`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        throw new Error("Error lors de changement de mot de passe");
    }
}

const addNewUser = async (userData) => {
    try {
        const response = await axiosService.post(`${BACKEND_URL}/api/user/inviteUser`, userData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        if (error.response) {
            return error.response?.data;
        } else {
            throw new Error("Error lors de l'ajout d'un utilisateur");
        }
    }
}

const deleteUser = async (id) => {
    try {
        const response = await axiosService.delete(`${BACKEND_URL}/api/user/deleteUser/${id}`);
        return response;
    } catch (error) {
        throw new Error("Error lors de la suppression d'un utilisateur");
    }
}
const editUser = async (id, userData) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await axiosService.put(`${BACKEND_URL}/api/user/updateUser/${id}`, userData, config);
        return response;
    } catch (error) {
        throw new Error("Error lors de la modification d'un utilisateur");
    }
}

export const UserService = {
    getAllUsers,
    changePassword,
    addNewUser,
    deleteUser,
    editUser
}