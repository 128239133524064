import { Button, Label, Modal, Select, TextInput } from 'flowbite-react'
import React from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserService } from '../../services/user-service';

const InviteUsers = ({ openModal, setOpenModalInviteUser }) => {

    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onFormSubmit = async (data) => {
        UserService.addNewUser(data)
            .then(response => {
                console.log(response);
                if (response.status === 201) {
                    toast.success(response?.message);
                    setOpenModalInviteUser(false);
                } else if (response.status === 409) {
                    toast.error(response?.message);
                    setOpenModalInviteUser(false);
                } else {
                    toast.error("Error survenue lors création du user");
                }
            })
    }
    return (
        <div>
            <Modal show={openModal} size="md" onClose={() => setOpenModalInviteUser(false)} popup>
                <Modal.Header>
                    <strong>{t('inviteUser')}</strong>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="space-y-6">
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="firstname" value={t('firstName')} />
                                </div>
                                <TextInput
                                    name="firstname"
                                    id="firstname"
                                    type="text"
                                    {...register("firstname", {
                                        required: true
                                    })}
                                />
                                {errors.firstname && errors.firstname.type === "required" && (
                                    <p className="text-red-600">{t('firstnameMsgErr')}</p>
                                )}
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="lastname" value={t('lastName')} />
                                </div>
                                <TextInput
                                    name="lastname"
                                    id="lastname"
                                    type="text"
                                    {...register("lastname", {
                                        required: true,
                                    })}
                                />
                                {errors.lastname && errors.lastname.type === "required" && (
                                    <p className="text-red-600">{t('lastnameMsgErr')}</p>
                                )}
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="email" value={t('email')} />
                                </div>
                                <TextInput
                                    name="email"
                                    id="email"
                                    type="email"
                                    {...register("email", {
                                        required: true
                                    })}
                                />
                                {errors.email && errors.email.type === "required" && (
                                    <p className="text-red-600">{t('emailMsgErr')}</p>
                                )}

                                {errors.password && errors.password.type === "required" && (
                                    <p className="text-red-600">{t('passwordMsgErr')}</p>
                                )}
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="email" value={t('password')} />
                                </div>
                                <TextInput
                                    name="password"
                                    id="password"
                                    type="password"
                                    {...register("password", {
                                        required: true
                                    })}
                                />
                                {errors.password && errors.password.type === "required" && (
                                    <p className="text-red-600">{t('passwordMsgErr')}</p>
                                )}
                            </div>
                            <div className="max-w-md">
                                <div className="mb-2 block">
                                    <Label htmlFor="countries" value="Select user role" />
                                </div>
                                <Select
                                    id="countries"
                                    required
                                    name='role'
                                    {...register("role", { required: true })}
                                >
                                    <option value="ADMIN">ADMIN</option>
                                    <option value="MANAGER">MANAGER</option>
                                    <option value="BLOGGER">BLOGGER</option>
                                </Select>
                                {errors.role && errors.role.type === "required" && (
                                    <p className="text-red-600">{t('roleRequiredMsg')}</p>
                                )}
                            </div>
                            <div className="w-full">
                                <Button type='submit' className='bg-primary-500'>{t('send')}</Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default InviteUsers