/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb } from "flowbite-react";
import { Link } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import CompanyInvoicesTable from "./CompanyInvoicesTable";


const CompanyInvoice = function () {
    const { t } = useTranslation();
    return (
        <>
            <div className="border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <div className="flex items-center gap-x-3">
                            <IoHomeOutline className="text-base" />
                            <span className="dark:text-white">
                                <Link to="/">{t('dashboard')}</Link>
                            </span>
                        </div>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/invoices">{t('company-demand')}</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <CompanyInvoicesTable />
        </>
    )
}

export default CompanyInvoice;