import React, { useState, useEffect } from 'react'
import { FaFileInvoice, FaFileInvoiceDollar, FaMoon, FaSignOutAlt, FaUsers } from 'react-icons/fa';
import { AiOutlineDashboard } from "react-icons/ai";
import { MdArticle } from 'react-icons/md';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from "../assets/images/favicon.png"
import { useTranslation } from 'react-i18next';
import { LuSun } from "react-icons/lu";
import { toast } from 'react-toastify';
import { userLogout } from '../redux/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { CgProfile } from 'react-icons/cg';



const SideBar = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [activeNavLink, setActiveNavLink] = useState(location.pathname);
    const [theme, setTheme] = useState("dark");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        setActiveNavLink(location.pathname);
    }, [location]);

    useEffect(() => {
        if (theme === 'dark') {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }
    }, [theme]);

    const handleThemeSwitch = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark')
    }

    const sideItems = [
        {
            label: t('dashboard'),
            icon: <AiOutlineDashboard className='dark:text-white text-gray-800' size={24} />,
            key: '/',
        }

    ];

    if (userInfo.role && userInfo.role.toLowerCase() === 'manager') {
        sideItems.push(
            {
                label: t('particular-demand'),
                icon: <FaFileInvoice className='dark:text-white text-gray-800' size={24} />,
                key: '/particular-demand'
            },
            {
                label: t('company-demand'),
                icon: <FaFileInvoice className='dark:text-white text-gray-800' size={24} />,
                key: '/company-demand'
            },
            {
                label: t('users'),
                icon: <FaUsers className='dark:text-white text-gray-800' size={24} />,
                key: '/users'
            }
        )
    }

    if (userInfo.role && userInfo.role.toLowerCase() === 'blogger') {
        sideItems.push(
            {
                label: t('articles'),
                icon: <MdArticle className='dark:text-white text-gray-800' size={24} />,
                key: '/blogs'
            }
        )
    }

    if (userInfo.role && userInfo.role.toLowerCase() === 'admin') {
        sideItems.push(
            {
                label: t('particular-demand'),
                icon: <FaFileInvoice className='dark:text-white text-gray-800' size={24} />,
                key: '/particular-demand'
            },
            {
                label: t('company-demand'),
                icon: <FaFileInvoice className='dark:text-white text-gray-800' size={24} />,
                key: '/company-demand'
            },
            {
                label: t('articles'),
                icon: <MdArticle className='dark:text-white text-gray-800' size={24} />,
                key: '/blogs'
            },
            {
                label: t('users'),
                icon: <FaUsers className='dark:text-white text-gray-800' size={24} />,
                key: '/users'
            }
        )
    }


    const handleSignOut = async () => {
        try {
            const result = await dispatch(userLogout());
            console.log(result);
            if (result.payload && result.payload.status === 200) {
                toast.success(result.payload.message);
                navigate("/authentication/sign-in")
            } else {
                toast.error('An error occurred during logout.');
            }

        } catch (error) {
            console.error('Error logging out:', error);
            toast.error("Error occurred while logging out the user");
        }
    };
    return (
        <aside className="relative top-0 flex flex-col w-72 items-start justify-between h-screen space-y-10 bg-gray-100 text-white dark:bg-gray-600">
            <div>
                <div className="mt-3 flex items-center justify-center space-x-2">
                    <img src={logo} alt="logo" className="h-10 w-10" />
                    <h1 className="text-xl font-bold text-gray-600 dark:text-white">AdLikeT</h1>
                </div>
                <div className='mt-5 flex flex-col gap-4 ml-1'>
                    {
                        sideItems.map((item, index) => {
                            return (
                                <span key={index}>
                                    <NavLink
                                        className={`flex items-center gap-2 p-1 text-gray-600 dark:text-white ${activeNavLink === item.key ? 'rounded bg-gray-500 text-white' : ''}`}
                                        to={item.key}
                                        onClick={() => setActiveNavLink(item.key)}
                                    >
                                        {item.icon}
                                        {item.label}
                                    </NavLink>
                                </span>
                            )
                        })
                    }
                </div>
            </div>

            <div className='flex flex-col w-full'>
                <hr className="border-spacing-1 border-gray-800 my-1 dark:border-white" />
                <NavLink
                    className={`flex items-center gap-2 p-1 text-gray-600 dark:text-white ${activeNavLink === '/user' ? 'rounded bg-gray-500 text-white' : ''}`}
                    to={'/user'}
                    onClick={() => setActiveNavLink('/user')}
                >
                    <CgProfile className='text-gray-800 dark:text-white' size={20} />
                    <span>{t('profile')}</span>
                </NavLink>
                <div
                    className='flex flex-row items-center gap-2 p-1 cursor-pointer dark:text-white text-gray-800'
                    onClick={() => handleSignOut()}
                >
                    <FaSignOutAlt size={20} />
                    <span>{t('logout')}</span>
                </div>
                <span
                    onClick={handleThemeSwitch}
                    className="w-10 cursor-pointer rounded-lg p-1 dark:text-white text-gray-600"
                >
                    {theme === "dark" ? <LuSun size={20} /> : <FaMoon size={20} />}
                </span>
            </div>
        </aside>
    )
}

export default SideBar