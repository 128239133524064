/* eslint-disable jsx-a11y/alt-text */
import { Breadcrumb } from 'flowbite-react'
import React, { useEffect, useRef, useState } from 'react'
import { IoHomeOutline } from 'react-icons/io5'
import { Link, useParams } from 'react-router-dom'
import { blogService } from '../../services/blog-service'
import { BACKEND_URL } from '../../config/config'
import { Skeleton } from 'antd'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const BlogDetails = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const data_is_synced = useRef(false);
    const { t } = useTranslation();
    const fetchBlogById = async (blogId) => {
        setLoading(true);
        try {
            const response = await blogService.getBlogById(blogId);
            setBlog(response);
        } catch (error) {
            toast.error("Une erreur s'est produite lors du chargement de blog.");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!data_is_synced.current) {
            fetchBlogById(id);
            data_is_synced.current = true;
        }
    }, [id])
    return (
        <>
            {loading && <Skeleton />}
            {!loading && (
                <div>
                    <div
                        className="border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                        <Breadcrumb>
                            <Breadcrumb.Item >
                                <div className="flex items-center gap-x-3">
                                    <IoHomeOutline className="text-base" />
                                    <span className="dark:text-white">
                                        <Link to="/">{t('dashboard')}</Link>
                                    </span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item >
                                <Link to="/blogs">{t('articles')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item >
                                {blog.id}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='mx-16 my-2'>
                        <img className='h-96 w-full' src={BACKEND_URL + blog.imgs} />
                        <div className='flex justify-between items-center'>
                            <h1 className='text-3xl font-bold my-4 text-blue-500 font-serif'>{blog.title}</h1>
                            <p className='text-sm font-bold my-4 text-gray-500 font-serif'>{t('publicationDate')} :
                                {
                                    new Date(blog.publication_date).toLocaleString('fr-FR', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })
                                }
                            </p>
                        </div>
                        <p className='text-lg my-4 font-serif dark:text-white'>{blog.description}</p>
                        <div className='text-sm my-4 font-serif dark:text-white'>
                            <div dangerouslySetInnerHTML={{ __html: blog.body }} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default BlogDetails