import React from 'react'
import { Outlet } from 'react-router-dom'

const MainContent = () => {
    return (
        <main className="relative flex h-full max-w-full overflow-y-hidden dark:bg-gray-800 p-3">
            <div className="m-4 flex h-full w-full auto-cols-max grid-flow-col flex-wrap items-start justify-start gap-4 overflow-y-scroll rounded-tl">
                <div className="h-full w-96 shrink-0 grow rounded-l">
                    <Outlet />
                </div>
            </div>
        </main>
    )
}

export default MainContent;
