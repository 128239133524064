/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaExclamationCircle } from "react-icons/fa";
import { Button, Label, Modal, TextInput } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { FaEnvelope } from 'react-icons/fa';
import { BsCalendarDateFill } from "react-icons/bs";
import { FaHouseChimneyUser } from "react-icons/fa6";
import {
  Breadcrumb,
} from "flowbite-react";

import { Link } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UserService } from "../../services/user-service";
import { updateUser } from "../../redux/auth/authSlice";


const UserProfile = function () {
  const [userInfo, setUserInfo] = useState(useSelector((state) => state.userInfo))
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch()

  const onFormSubmit = (userData) => {
    UserService.editUser(userInfo.id, userData)
      .then(data => {
        if (data.status === 200) {
          dispatch(updateUser(userData));
          setUserInfo(
            {
              id: userInfo.id,
              firstname: userData.firstname ? userData.firstname : userInfo.firstname,
              lastname: userData.lastname ? userData.lastname : userInfo.lastname,
              email: userData.email ? userData.email : userInfo.email,
              role: userInfo.role,
              created_at: userInfo.created_at
            }
          )
          toast.success(data?.message);
          setShowModal(!showModal)
        }
      })
  }
  return (
    <>
      <div>
        <div
          className="border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <Breadcrumb>
            <Breadcrumb.Item >
              <div className="flex items-center gap-x-3">
                <IoHomeOutline className="text-base" />
                <span className="dark:text-white">
                  <Link to="/">{t('dashboard')}</Link>
                </span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
              <Link to="/blogs">{t('user')}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full lg:h-full dark:bg-gray-800 rounded-lg">
              <div className="bg-gray-300 shadow-md rounded-lg overflow-hidde dark:bg-gray-600 mr-2">
                <div className="dark:bg-gray-600">
                  <img className="w-full h-48 object-cover" src="https://www.freecodecamp.org/news/content/images/size/w2000/2021/06/w-qjCHPZbeXCQ-unsplash.jpg" alt="profile cover" />
                </div>
                <div className="p-4 dark:bg-gray-400 bg-gray-500">
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-1/3 md:w-1/3 sm:w-full mb-">
                      <div className="flex justify-center">
                        <img className="rounded-full w-24 h-24 object-cover" src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="profile picture" />
                      </div>
                    </div>
                    <div className="w-full lg:w-2/3 md:w-2/3 sm:w-full ">
                      <div className="text-xl font-semibold">
                        {userInfo.firstname} {userInfo.lastname} <span className="bg-green-500 dark:text-white text-sm rounded px-2 py-1 ml-2">{userInfo.role}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between dark:bg-gray-700 dark:text-white p-4">
                  <ul className="space-y-2">
                    <li className="flex items-center"><FaEnvelope className="mr-2 text-gray-800 dark:text-white" /> {userInfo.email}</li>
                    <li className="flex items-center"><FaHouseChimneyUser className="mr-2 text-gray-800 dark:text-white" /> {userInfo.role}</li>
                    <li className="flex items-center"><BsCalendarDateFill className="mr-2 text-gray-800 dark:text-white" />{userInfo.created_at}</li>
                  </ul>
                  {
                    userInfo.role === "ADMIN" &&
                    <div className="flex flex-col gap-2">
                      <Button
                        className="bg-green-500"
                        onClick={() => setShowModal(true)}
                      >
                        {t('editProfile')}
                      </Button>
                      <Button
                        className="bg-red-500"
                        onClick={() => setShowModalDelete(true)}
                      >
                        {t('deleteUserAccount')}
                      </Button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal onClose={() => setShowModal(false)} show={showModal}>
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>{t('editUser')}</strong>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-4">
                  <Label htmlFor="firstname">{t('firstname')}</Label>
                  <div className="mt-1">
                    <TextInput
                      id="firstname"
                      name="firstname"
                      defaultValue={userInfo.firstname}
                      {...register("firstname")}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-4">
                  <Label htmlFor="lastName">{t('lastName')}</Label>
                  <div className="mt-1">
                    <TextInput
                      id="lastname"
                      name="lastname"
                      defaultValue={userInfo.lastname}
                      {...register("lastname")}

                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-4">
                  <Label htmlFor="email">{t('email')}</Label>
                  <div className="mt-1">
                    <TextInput
                      id="email"
                      name="email"
                      defaultValue={userInfo.email}
                      {...register("email")}

                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-4">
                  <Button type="submit" className="bg-primary-500">
                    {t('save')}
                  </Button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal >
        <Modal show={showModalDelete} size="md" onClose={() => setShowModalDelete(false)} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <FaExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {t('deleteUserMsgAcc')}
              </h3>
              <div className="flex justify-center gap-4">
                <Button color="failure" onClick={() => setShowModalDelete(false)}>
                  {t('confirm')}
                </Button>
                <Button color="gray" onClick={() => setShowModalDelete(false)}>
                  {t('cancel')}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

    </>
  );
};



export default UserProfile;
