import React from 'react'
import SideBar from '../components/SideBar';
import HeaderMain from '../components/HeaderMain';
import MainContent from '../components/MainContent';


const MainLayout = () => {

    return (
        <div className='relative flex h-screen w-full overflow-hidden bg-white'>
            <SideBar />
            <div className='flex w-full flex-col justify-between'>
                <HeaderMain />
                <MainContent />
            </div>
        </div>
    )
}

export default MainLayout