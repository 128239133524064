import { useEffect, useRef, useState } from "react";
import { dashboardService } from "../services/dashboard-service";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import WorldMap from "./dashboard/HighchartsBarChart";
import ChartComponent from "./dashboard/ChartComponent";

const DashboardPage = function () {
  const [loading, setLoading] = useState(true);
  const data_is_synced = useRef(false);
  // const [totalInvoices, setTotalInvoices] = useState(0);
  const [totalInvoicesParticular, setTotalInvoicesParticular] = useState(0);
  const [totalInvoicesCompany, setTotalInvoicesCompany] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [googleDataCity, setGoogleDataCity] = useState([]);
  const [googleDataCountry, setGoogleDataCountry] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (!data_is_synced.current) {
      dashboardService.getTotalBlogs()
        .then(response => {
          setTotalBlogs(response.data.totalBlogs);
          setLoading(false);
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));

      dashboardService.getTotalInvoicesParticular()
        .then(response => {
          setTotalInvoicesParticular(response.data.totalInvoices);
          setLoading(false);
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));

      dashboardService.getTotalInvoicesCompany()
        .then(response => {
          setTotalInvoicesCompany(response.data.totalInvoices);
          setLoading(false);
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));

      dashboardService.getTotalUsers()
        .then(response => {
          setTotalUsers(response.data.totalUsers);
          setLoading(false);
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));

      dashboardService.getGoogleAnalyticsByCity()
        .then(response => {
          setGoogleDataCity(response.data);
          setLoading(false);
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));

      dashboardService.getGoogleAnalyticsByCountry()
        .then(response => {
          setGoogleDataCountry(response.data);
          setLoading(false);
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));
      setLoading(false);
      data_is_synced.current = true;
    }
  }, []);



  return (
    <>
      {loading && <Skeleton />}
      {!loading && (
        <div className="px-4 py-6 lg:px-8 lg:py-10">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
              <Link to="/blogs" className="flex flex-col justify-between bg-gray-200 dark:bg-gray-800 rounded-lg p-6 shadow-md hover:bg-gray-300 dark:hover:bg-gray-700 transition">
                <h3 className="text-lg font-semibold dark:text-white">{t('totalArticles')}</h3>
                <span className="text-2xl font-bold dark:text-white">{totalBlogs}</span>
              </Link>
              <Link to="/particular-demand" className="flex flex-col justify-between bg-gray-200 dark:bg-gray-800 rounded-lg p-6 shadow-md hover:bg-gray-300 dark:hover:bg-gray-700 transition">
                <h3 className="text-lg font-semibold dark:text-white">{t('totalParticularReequirements')}</h3>
                <span className="text-2xl font-bold dark:text-white">{totalInvoicesParticular}</span>
              </Link>
              <Link to="/company-demand" className="flex flex-col justify-between bg-gray-200 dark:bg-gray-800 rounded-lg p-6 shadow-md hover:bg-gray-300 dark:hover:bg-gray-700 transition">
                <h3 className="text-lg font-semibold dark:text-white">{t('totalCompanyReequirements')}</h3>
                <span className="text-2xl font-bold dark:text-white">{totalInvoicesCompany}</span>
              </Link>
              <Link to="/users" className="flex flex-col justify-between bg-gray-200 dark:bg-gray-800 rounded-lg p-6 shadow-md hover:bg-gray-300 dark:hover:bg-gray-700 transition">
                <h3 className="text-lg font-semibold dark:text-white">{t('totalUsers')}</h3>
                <span className="text-2xl font-bold dark:text-white">{totalUsers}</span>
              </Link>
            </div>
            <div className="px-4 py-6">
              <div className="max-w-1200 mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="rounded-lg border border-gray-300 dark:border-gray-700 shadow-lg h-[400px] md:h-[500px] lg:h-[600px] bg-white dark:bg-gray-900 overflow-hidden">
                  <WorldMap data={googleDataCountry} />
                </div>
                <div className="rounded-lg border border-gray-300 dark:border-gray-700 shadow-lg h-[400px] md:h-[500px] lg:h-[600px] bg-white dark:bg-gray-900 overflow-hidden">
                  <ChartComponent data={googleDataCity} />
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
    </>
  )

};

export default DashboardPage;
