import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Invoice = ({ invoice }) => {
    const { t } = useTranslation();
    const invoiceRef = useRef(null); // Create a ref to capture the invoice content

    // Define the services to be displayed
    const services = [
        { key: 'hoursNumber', label: t("hoursNumber") },
        { key: 'nmbrPageBelingue', label: t("nmbrPageBelingue") },
        { key: 'abonnementSEO', label: t("abonnementSEO") },
        { key: 'ameliorationSEO', label: t("ameliorationSEO") },
        { key: 'analyseStatistique', label: t("analyseStatistique") },
        { key: 'hebergementMessagerie', label: t("hebergementMessagerie") },
        { key: 'formationGestion', label: t("formationGestion") },
        { key: 'assistanceTech', label: t("assistanceTech") },
        { key: 'plateformeEcommerce', label: t("plateformeEcommerce") },
        { key: 'gestionCompte', label: t("gestionCompte") },
        { key: 'redactionContenu', label: t("redactionContenu") },
        { key: 'newsLetter', label: t("newsLetter") },
        { key: 'calandrierPrice', label: t("calandrierPrice") },
        { key: 'renouvelementContenu', label: t("renouvelementContenu") },
    ];

    // Filter out non-null, defined services
    const nonNullServices = services.filter(({ key }) => invoice[key] !== null && invoice[key] !== undefined);

    // Determine if the table should be displayed
    const shouldDisplayTable = nonNullServices.length > 0;

    // Format price with apostrophes
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    };


    return (
        <div ref={invoiceRef} className="max-w-full mx-auto p-6 bg-white dark:bg-gray-900 shadow-md rounded-lg">
            <h2 className="text-3xl font-semibold mb-8 text-center text-gray-900 dark:text-gray-100">
                {t("demandTitle")}
            </h2>

            <div className="mb-6 flex justify-between">
                <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {t("lastName") + " et " + t("firstName") + " : " + invoice.lastname + " " + invoice.firstname}
                    </p>
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {t("telephone") + " : " + invoice.phone_number}
                    </p>
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {t("email") + " : " + invoice.email}
                    </p>
                    {invoice.is_company === 1 && (
                        <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                            {t("company") + " : " + invoice.company_name}
                        </p>
                    )}
                </div>
                <div className="text-right">
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {invoice.adresse}
                    </p>
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {invoice.country}
                    </p>
                </div>
            </div>

            {invoice.serviceTitle && (
                <div className="mb-4 text-xl font-semibold text-center text-gray-900 dark:text-gray-100">
                    {invoice.serviceTitle}
                </div>
            )}

            {shouldDisplayTable && (
                <table className="min-w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="border-b py-3 text-left text-gray-900 dark:text-gray-100">Description</th>
                            <th className="border-b py-3 text-right text-gray-900 dark:text-gray-100">{t("price")} (CHF)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nonNullServices.map(({ key, label }) => (
                            <tr key={key}>
                                <td className="border-b py-2 text-left text-gray-900 dark:text-gray-100">{label}</td>
                                <td className="border-b py-2 text-right text-gray-900 dark:text-gray-100">
                                    {invoice[key] !== null && invoice[key] !== undefined ? formatPrice(invoice[key]) : '-'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="border-t py-3 text-left font-semibold text-gray-900 dark:text-gray-100">{t("totalPriceIndicatif")}</td>
                            <td className="border-t py-3 text-right font-semibold text-gray-900 dark:text-gray-100">
                                {invoice.totalPrice !== null && invoice.totalPrice !== undefined ? formatPrice(invoice.totalPrice) : '—'} CHF
                            </td>
                        </tr>
                    </tfoot>
                </table>
            )}
        </div>
    );
};

export default Invoice;
