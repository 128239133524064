import { BACKEND_URL } from "../config/config"
import axiosService from "./interceptor-service"




const getAllBlogs = async (page, search) => {
    console.log(search);
    try {
        let apiUrl = `${BACKEND_URL}/api/blog/articles/notPublished/${page}`
        if (search) {
            apiUrl += `?search=${search}`;
        }
        const response = await axiosService.get(apiUrl);
        return response;
    } catch (error) {
        throw new Error(error.response.data.message || "Error fetching blogs");
    }
};

const getBlogById = async (id) => {
    try {
        const response = await axiosService.get(`${BACKEND_URL}/api/blog/articles/details/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Error fetching Invoices");
    }
}


const deleteBlogById = async (invoiceId) => {
    try {
        const response = await axiosService.delete(`${BACKEND_URL}/api/blog/article/${invoiceId}`);
        return response;
    } catch (error) {
        throw new Error(error.response.data.message || "Error fetching Invoices");
    }
}


export const blogService = {
    getAllBlogs,
    deleteBlogById,
    getBlogById
}