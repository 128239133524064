import React, { useEffect, useRef, useState } from "react";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";

function WorldMap({ data }) {
    const countriesRef = useRef({});
    const [markers, setMarkers] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (data) {
            const newCountries = {};
            const newMarkers = [];

            data.forEach((item) => {
                if (item.isoCode && item.value) {
                    newCountries[item.isoCode] = parseInt(item.value, 10);
                    newMarkers.push({
                        latLng: [0, 0], // Placeholder
                        name: item.city,
                    });
                }
            });

            countriesRef.current = newCountries; // Store countries data for later use
            setMarkers(newMarkers);
            setLoading(false); // Data is processed, set loading to false
        }
    }, [data]);

    const colorScale = ["#E2AEFF", "#5E32CA"];


    return (
        <div className="relative w-full h-full dark:bg-white">
            {loading ? (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <p>Loading...</p>
                </div>
            ) : (
                <VectorMap
                    className="w-full h-full dark:bg-gray-700 bg-gray-400"
                    map={worldMill}
                    containerStyle={{
                        width: "100%",
                        height: "100%",
                    }}
                    backgroundColor="transparent"
                    markers={markers}
                    markerStyle={{
                        initial: {
                            fill: "red",
                        },
                    }}
                    series={{
                        regions: [
                            {
                                scale: colorScale,
                                values: countriesRef.current,
                                min: 0,
                                max: 10,
                            },
                        ],
                    }}
                    onRegionTipShow={(event, label, code) => {
                        const value = countriesRef.current[code] || 0;
                        label.html(`
                            <div style="background-color: black; border-radius: 6px; min-height: 50px; width: 125px; color: white; padding-left: 10px">
                                <p>
                                    <b>${label.html()}</b>
                                </p>
                                <p>${value}</p>
                            </div>`);
                    }}
                    onMarkerTipShow={(event, label, code) => {
                        label.html(`
                            <div style="background-color: white; border-radius: 6px; min-height: 50px; width: 125px; color: black !important; padding-left: 10px">
                                <p style="color: black !important;">
                                    <b>${label.html()}</b>
                                </p>
                            </div>`);
                    }}
                />
            )}
        </div>
    );
}

export default WorldMap;
