import axios from 'axios';
import { BACKEND_URL } from '../config/config';
import { AUTH_TOKEN } from '../constants/Auth-constants';
import { toast } from "react-toastify";


const unauthorizedCods = [400, 401, 403, 404, 500, 508];

const axiosService = axios.create({
    baseURL: BACKEND_URL,
    timeout: 60000
});

const TOTKEN_PAYLOAD_KEY = "authorization";

const getTokenExpiration = (jwtToken) => {
    return JSON.parse(atob(jwtToken.split('.')[1])).exp * 1000; // Convertir en millisecondes
};

const redirectToLogin = () => {
    localStorage.removeItem(AUTH_TOKEN);
    window.location.href = "/authentication/sign-in";
}

const errorMessages = {
    "400": "Bad Request",
    "401": "Unauthorized",
    "403": "Forbidden",
    "404": "Not Found",
    "500": "Internal Server Error",
    "508": "Timout"
};

axiosService.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
        const tokenExpiration = getTokenExpiration(jwtToken);
        if (Date.now() >= tokenExpiration) {
            redirectToLogin();
            return Promise.reject(new Error("Token has expired"));
        }
        config.headers[TOTKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }
    return config;
}, error => {
    toast.error('Error occurred while trying to send request');
    return Promise.reject(error);
});

axiosService.interceptors.response.use(response => {
    return response.data;
}, error => {
    const status = error.response ? error.response.status : null;
    const message = status && unauthorizedCods.includes(status) ? errorMessages[status] || "An error occurred"
        : "Network error occurred";

    if (status && unauthorizedCods.includes(status)) {
        if ([400, 401, 403].includes(status)) {
            redirectToLogin();
        }
        toast.error(message);
    } else {
        toast.error(message);
    }

    console.error('API Error: ', error);
    return Promise.reject(error);
});

export default axiosService;
