/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Button, Label, Modal, TextInput } from "flowbite-react";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axiosService from "../../services/interceptor-service";
import { BACKEND_URL } from "../../config/config";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../../services/user-service';

const ChangePassword = ({ openModal, setOpenModalPassword }) => {
    const { t } = useTranslation();
    const { register, watch, handleSubmit, formState: { errors } } = useForm();
    const newPassword = watch('newPassword');
    const userInfo = useSelector((state) => state.userInfo);
    const navigate = useNavigate();

    const validatePasswordMatch = (value) => {
        if (value === newPassword) {
            return true;
        }
        return false;
    };

    const onFormSubmit = (data) => {
        if (validatePasswordMatch(data.confirmNewPassword)) {
            console.log(data)
            const jsonData = {
                email: userInfo.email,
                newPassword: data.newPassword
            }
            UserService.changePassword(jsonData)
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        toast.success(response?.message);
                        localStorage.removeItem('userToken');
                        setOpenModalPassword(false);
                        setTimeout(() => {
                            navigate('/authentication/sign-in');
                        }, 2000);
                    } else {
                        toast.error("Error survenue lors de changement de mot de passe");
                    }
                })
                .catch(error => {
                    console.log(error?.message)
                    toast.error("Error lors de changement de mot de passe");
                })

        } else {
            toast.error(t('passwordsDoNotMatch'))
        }
    }
    return (
        <div>
            <Modal show={openModal} size="md" onClose={() => setOpenModalPassword(false)} popup>
                <Modal.Header>
                    <strong>{t('editPassword')}</strong>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="space-y-6">
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="oldPassword" value={t('oldPassword')} />
                                </div>
                                <TextInput
                                    name="oldPassword"
                                    id="oldPassword"
                                    type="password"
                                    {...register("oldPassword", {
                                        required: true
                                    })}
                                />
                                {errors.oldPassword && errors.oldPassword.type === "required" && (
                                    <p className="text-red-600">{t('oldPasswordErrMsg')}</p>
                                )}
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="password" value={t('newPassword')} />
                                </div>
                                <TextInput
                                    name="newPassword"
                                    id="newPassword"
                                    type="password"
                                    {...register("newPassword", {
                                        required: true,
                                        validate: validatePasswordMatch,
                                    })}
                                />
                                {errors.newPassword && errors.newPassword.type === "required" && (
                                    <p className="text-red-600">{t('newPasswordErrMsg')}</p>
                                )}
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="password" value={t('confirmNewPassword')} />
                                </div>
                                <TextInput
                                    name="confirmNewPassword"
                                    id="confirmNewPassword"
                                    type="password"
                                    {...register("confirmNewPassword", {
                                        required: true
                                    })}
                                />
                                {errors.confirmNewPassword && errors.confirmNewPassword.type === "required" && (
                                    <p className="text-red-600">{t('ConfirmNewPasswordErrMsg')}</p>
                                )}
                                {errors.confirmNewPassword && errors.confirmNewPassword.type === 'validate' && (
                                    <p className="text-red-600">{t('passwordsDoNotMatch')}</p>
                                )}
                            </div>
                            <div className="w-full">
                                <Button type='submit' className='bg-primary-500'>{t('save')}</Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ChangePassword