import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_URL } from "../../config/config";
import axios from "axios";
import axiosService from "../../services/interceptor-service";


export const userLogin = createAsyncThunk("/auth/signin", async ({ email, password }, { rejectWithValue }) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await axios.post(`${BACKEND_URL}/auth/signin`, { email, password }, config);
        const responseData = await response.data.data;
        localStorage.setItem("userToken", responseData.access_token);
        return responseData;
    } catch (error) {
        // Return custom error message from API if any
        if (error.response?.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue("An error occurred during login.");
        }
    }
}
);

export const userLogout = createAsyncThunk('auth/signout', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosService.post(`${BACKEND_URL}/auth/signout`);
        if (response.status === 200) {
            localStorage.removeItem("userToken")
        }
        return response
    } catch (error) {
        if (error.response?.data?.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during logout.');
        }
    }
}
);
