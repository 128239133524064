import { BACKEND_URL } from "../config/config"
import axiosService from "./interceptor-service";




const getAllInvoices = async (page, sorter, isCompany) => {
    console.log("iscompany", isCompany);
    try {
        let url = `${BACKEND_URL}/api/invoices/${page}`;
        if (Object.keys(sorter).length > 0) {
            url += `?sortField=${sorter.field}&sortOrder=${sorter.order}&isCompany=${isCompany}`;
        } else {
            url += `?isCompany=${isCompany}`;
        }
        const response = await axiosService.get(url);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Error fetching Invoices");
    }
};

const getInvoiceById = async (invoiceId) => {
    try {
        const response = await axiosService.get(`${BACKEND_URL}/api/invoices/invoice/${invoiceId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Error fetching Invoices");
    }
}

const deleteInvoiceById = async (invoiceId) => {
    try {
        const response = await axiosService.delete(`${BACKEND_URL}/api/invoices/invoice/${invoiceId}`);
        return response;
    } catch (error) {
        throw new Error(error.response.data.message || "Error fetching Invoices");
    }
}


export const InvoicesService = {
    getAllInvoices,
    getInvoiceById,
    deleteInvoiceById
}