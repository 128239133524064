import { createSlice } from "@reduxjs/toolkit"
import { userLogin, userLogout } from "./authActions";


const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState = {
    loading: false,
    userInfo: {},
    accessToken: userToken,
    error: null,
    success: false,
    isError: false
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearState: (state) => {
            state.success = false;
            state.loading = false;
            state.isError = false;
            return state;
        },
        updateUser: (state, action) => {            
            if(action.payload.email) state.userInfo.email = action.payload.email;
            if(action.payload.firstname) state.userInfo.firstname = action.payload.firstname;
            if(action.payload.lastname) state.userInfo.lastname = action.payload.lastname;

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                state.loading = false;
                state.userInfo = action.payload;
                if (action.payload) {
                    state.userInfo = action.payload.user;
                    state.accessToken = action.payload.access_token;
                    state.success = true
                    state.isError = false;
                }
                return state;
            })
            .addCase(userLogin.rejected, (state, action) => {
                state.loading = false;
                console.log(action.error.message);
                if (action.error.message === "Request failed with status code 401") {
                    state.error = "Access Denied! Invalid credentials";
                } else {
                    state.error = action.error.message;
                }
                state.isError = true;
            })
            .addCase(userLogout.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogout.fulfilled, (state) => {
                state.loading = false;
                state.accessToken = null;
                state.userInfo = {};
                state.success = false;
            })
            .addCase(userLogout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.isError = true;
            });
    },
});

export default authSlice.reducer;

export const { clearState, updateUser } = authSlice.actions;