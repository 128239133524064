/* eslint-disable react/style-prop-object */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { InvoicesService } from '../../services/invoices-service';
import { Pagination, Skeleton } from 'antd';
import { Badge, Button, Modal } from 'flowbite-react';
import { FaDownload, FaExclamationCircle, FaEye, FaSortAlphaDown, FaSortAlphaUp, FaTrashAlt } from "react-icons/fa";
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import "../../styles/InvoicesTable.css"
import { toast } from 'react-toastify';
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import InvoiceCompany from './InvoicePdfGen/InvoiceCompany';
import InvoiceIndividual from './InvoicePdfGen/InvoiceIndividual';
import InvoiceDetails from './invoiceDetails';

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

    return formattedDate;
};

const formatDateNow = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

const CompanyInvoicesTable = () => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const data_is_synced = useRef(false);
    const [totalInvoices, setTotalInvoices] = useState(0);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [pdfVisible, setPdfVisible] = useState(false);
    const [pdfIndividualVisible, setPdfIndividualVisible] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const [sorter, setSorter] = useState({});
    const [sortedColumn, setSortedColumn] = useState(null);
    const { t } = useTranslation();
    const userInfo = useSelector((state) => state.userInfo);
    const columns = [
        { title: t('firstName'), dataIndex: 'firstname', key: 'firstname', sorter: true, width: 100 },
        { title: t('lastName'), dataIndex: 'lastname', key: 'lastname', sorter: true, width: 100 },
        { title: t('email'), dataIndex: 'email', key: 'email', sorter: true, width: 100 },
        { title: t('telephone'), dataIndex: 'phone_number', key: 'phone_number', sorter: true, width: 100 },
    ];


    const handleShowDeletionModal = (recordID) => {
        setSelectedId(recordID)
        setOpenModal(true)
    }

    const fetchAllInvoices = async (page, sorter, isCompany) => {
        setLoading(true);
        InvoicesService.getAllInvoices(page, sorter, isCompany)
            .then(response => {
                console.log(response);
                setInvoices(response.invoices);
                setTotalInvoices(response.totalInvoices);
            })
            .catch(error => {
                console.log(error.message);
                toast.error("Une erreur s'est produite lors du chargement des demandes.");
            })
        setLoading(false);
    };

    const deleteInvoiceById = async (id) => {
        try {
            const response = await InvoicesService.deleteInvoiceById(Number(id));
            if (response?.status === 200) {
                const updatedInvoices = invoices.filter((invoice) => invoice.id !== id);
                setInvoices(updatedInvoices);
                if (updatedInvoices.length <= 6) {
                    const totalPagesAfterDelete = Math.ceil((totalInvoices - 1) / 6);
                    if (currentPage >= totalPagesAfterDelete) {
                        handlePageChange(totalPagesAfterDelete, sorter, true)
                    } else {
                        handlePageChange(currentPage, sorter, true)
                    }
                }
                toast.success(response?.message);
            }

        } catch (error) {
            toast.error("Failed to fetch invoice details")
        }
    }

    useEffect(() => {
        if (!data_is_synced.current) {
            fetchAllInvoices(currentPage, sorter, true);
            data_is_synced.current = true;
        }
    }, [currentPage, sorter]);

    const handlePageChange = (page, sorter, isCompany) => {
        setCurrentPage(page);
        fetchAllInvoices(page, sorter, isCompany)
    };

    const handleDeleteInvoice = () => {
        deleteInvoiceById(selectedId.id)
        setOpenModal(false);
    }
    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <Link to="#" className='dark:text-white dark:bg-gray-400 bg-gray-300 p-2 rounded-lg text-black'>{t('previeus')}</Link>;
        }
        if (type === 'next') {
            return <Link to="#" className='dark:text-white dark:bg-gray-400 bg-gray-300 p-2 rounded-lg text-black'>{t('next')}</Link>;
        }
        return originalElement;
    }

    const handleSort = (columnKey) => {
        const isAscend = sortedColumn === columnKey && sorter.order === 'ascend';
        const newSortOrder = isAscend ? 'descend' : 'ascend';
        setSorter({ field: columnKey, order: newSortOrder });
        setSortedColumn(columnKey);
        handlePageChange(currentPage, { field: columnKey, order: newSortOrder }, true)
    };


    const companyColumns = [
        ...columns,
        { title: t('company'), dataIndex: 'company_name', key: 'company_name', sorter: true, width:200 },
        { title: t('status'), dataIndex: 'is_finished', key: 'is_finished', width: 100 },
        { title: t('country'), dataIndex: 'country', key: 'country', sorter: true, width: 100 },
        { title: t('city'), dataIndex: 'ville', key: 'ville', sorter: true, width: 100 },
        { title: t('date'), dataIndex: 'created_at', key: 'created_at', sorter: true, width: 100 },
        userInfo.role === "ADMIN" && { title: 'Actions', dataIndex: 'actions', key: 'company_actions', width: 100 },
    ].filter(Boolean);

    const handleDownloadPDF = async (invoice) => {
        const timestamp = formatDateNow();
        const filename = `demande_${timestamp}.pdf`;
        // Create a PDF document
        const blob = await pdf(<InvoiceCompany invoice={invoice} />).toBlob();

        // Create a link element for downloading the blob
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <div className='p-2'>
            {
                loading ? <Skeleton /> : (
                    <Fragment >
                        <div className="relative overflow-x-auto">
                            <table className="w-full my-2 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-sm text-gray-700 first-letter:uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        {companyColumns.map((column) => (
                                            <td
                                                style={{ minWidth: column.width }}
                                                key={column.key}
                                                className={`px-6 py-3 cursor-pointer w-20 ${column.sorter ? 'hover:bg-gray-200' : ''
                                                    }`}
                                                onClick={() => column.sorter ? handleSort(column.dataIndex) : null}
                                            >
                                                {column.title}
                                                {column.sorter && sortedColumn === column.dataIndex && (
                                                    sorter.order === 'ascend' ? <FaSortAlphaUp className="inline ml-1" size={16} /> : <FaSortAlphaDown size={16} className="inline ml-1" />
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invoices.map((invoice) => (
                                            <tr key={invoice.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    {invoice.firstname}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    {invoice.lastname}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    {invoice.email}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    {invoice.phone_number}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    <Badge color="success">{invoice.company_name}</Badge>
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    {invoice.is_finished === 1 ? <Badge color="success">{t('yes')}</Badge> : <Badge color="failure">{t('no')}</Badge>}
                                                    <Badge></Badge>
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    {invoice.country}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    {invoice.ville}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    {invoice.created_at}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white w-20">
                                                    <div className='flex gap-3'>
                                                        <span
                                                            className='cursor-pointer text-lg'
                                                            onClick={() => {
                                                                setSelectedInvoice(invoice);
                                                                setShowDetails(true)
                                                            }}>
                                                            <FaEye size={20} />
                                                        </span>
                                                        <span
                                                            className='cursor-pointer text-lg'
                                                            onClick={() => handleDownloadPDF(invoice)}>
                                                            <FaDownload size={20} />
                                                        </span>
                                                        {
                                                            userInfo.role === "ADMIN" &&
                                                            <span className='cursor-pointer text-lg' onClick={() => handleShowDeletionModal(invoice)}>
                                                                <FaTrashAlt size={20} />
                                                            </span>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="sm:justify-center my-2">
                            <Pagination
                                total={totalInvoices}
                                pageSize={pageSize}
                                onChange={(page, size) => {
                                    handlePageChange(page, sorter, true);
                                    setPageSize(size);
                                }}
                                className="dark:text-white"
                                itemRender={itemRender}
                                current={currentPage}
                            />
                        </div>
                    </Fragment>
                )
            }
            <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <FaExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t('deleteDemandMsg')}
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={handleDeleteInvoice}>
                                {"Confirmer"}
                            </Button>
                            <Button color="gray" onClick={() => setOpenModal(false)}>
                                {t('cancel')}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={pdfVisible} size="md" onClose={() => setPdfVisible(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <FaExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t('downloadPdfMsg')}
                        </h3>
                        <div className="flex justify-center gap-4">
                            {selectedInvoice && (
                                <PDFDownloadLink
                                    document={<InvoiceCompany invoice={selectedInvoice} />}
                                    fileName={`${t('demanded')}-${selectedInvoice.id}_${formatDate(new Date().toISOString())}.pdf`}
                                >
                                    {({ loading }) => (loading ?
                                        <Button
                                            className='dark:bg-primary-500 bg-primary-500'
                                            disabled
                                        >
                                            {t('downloading')}
                                        </Button>
                                        :
                                        <Button
                                            className='dark:bg-primary-500 bg-primary-500'
                                            onClick={() => {
                                                setTimeout(() => {
                                                    setPdfVisible(false);
                                                }, 3000);
                                            }}
                                        >
                                            {t('downloadpdf')}
                                        </Button>
                                    )}
                                </PDFDownloadLink>
                            )}
                            <Button
                                color="gray"
                                onClick={() => setPdfVisible(false)}
                                className='dark:bg-red-500 bg-red-500'
                            >
                                {t('cancel')}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={pdfIndividualVisible} size="md" onClose={() => setPdfIndividualVisible(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <FaExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t('downloadPdfMsg')}
                        </h3>
                        <div className="flex justify-center gap-4">
                            {selectedInvoice && (
                                <PDFDownloadLink
                                    document={<InvoiceIndividual invoice={selectedInvoice} />}
                                    fileName={`${t('demanded')}-${selectedInvoice.id}_${formatDate(new Date().toISOString())}.pdf`}
                                >
                                    {({ loading }) => (loading ?
                                        <Button
                                            className='dark:bg-primary-500 bg-primary-500'
                                            disabled
                                        >
                                            {t('downloading')}
                                        </Button>
                                        :
                                        <Button
                                            className='dark:bg-primary-500 bg-primary-500'
                                            onClick={() => {
                                                setTimeout(() => {
                                                    setPdfIndividualVisible(false);
                                                }, 3000);
                                            }}
                                        >
                                            {t('downloadpdf')}
                                        </Button>
                                    )}
                                </PDFDownloadLink>
                            )}
                            <Button
                                color="gray"
                                onClick={() => setPdfIndividualVisible(false)}
                                className='dark:bg-red-500 bg-red-500'
                            >
                                {t('cancel')}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showDetails} onClose={() => setShowDetails(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <InvoiceDetails invoice={selectedInvoice} />
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat={true} onClick={() => setShowDetails(false)} className='bg-red-500 hover:bg-red-300'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default CompanyInvoicesTable