import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element: Component, roles, ...rest }) => {
    const location = useLocation();
    const isAuthenticated = useSelector((state) => !!state.accessToken);
    const userRole = useSelector((state) => state.userInfo.role);

    if (!isAuthenticated) {
        return <Navigate to="/authentication/sign-in" state={{ from: location }} />;
    }

    if (roles && roles.indexOf(userRole.toLowerCase()) === -1) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return Component;
};

export default ProtectedRoute;

