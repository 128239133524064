/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Button, Card, Label, Spinner, TextInput } from "flowbite-react";
import { userLogin } from "../../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import logo from "../../assets/images/Color_logo.png"


const SignInPage = function () {
  const loading = useSelector((state) => state.loading)
  const error = useSelector((state) => state.error)
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const submitForm = (data) => {
    dispatch(userLogin(data))
      .then(result => {
        if (result) {
          console.log(result);
          navigate("/")
        }
      })
  }
  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
      <Card className="w-full max-w-md lg:w-3/4 xl:max-w-lg">
        <div className="flex items-center justify-center bg-gray-100 p-6">
          <img
            height={70}
            src={logo}
            className="h-12"
          />
        </div>
        <div className="p-6">
          <form onSubmit={handleSubmit(submitForm)}>
            {error && <Alert title={error} />}
            <div className="mb-4 flex flex-col gap-y-3">
              <Label htmlFor="email">Votre email</Label>
              <TextInput
                id="email"
                placeholder="info@company.com"
                type="email"
                {...register("email")}
              />
            </div>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label htmlFor="password">Votre password</Label>
              <TextInput
                id="password"
                placeholder="****************"
                type="password"
                {...register("password")}
              />
            </div>
            <div className="mb-6 flex items-end justify-end">
              <a
                href="#"
                className="text-sm text-primary-500 dark:text-primary-300"
              >
                Mot de passe oublié?
              </a>
            </div>
            <div className="mb-6">
              <Button type="submit" className="w-full bg-primary-500" disabled={loading}>
                {loading ? <Spinner /> : 'Se connecter'}
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default SignInPage;
