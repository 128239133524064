/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import { Modal } from "flowbite-react";
import { useForm } from 'react-hook-form';
import axiosService from "../../services/interceptor-service";
import { BACKEND_URL } from "../../config/config";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useRef, useState } from "react";
import defaultImg from "../../assets/images/defaultImg.jpg"

const AddBlogModal = function ({ isOpen, setOpen, updateBlogs }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { t } = useTranslation();
    const [value, setValue] = useState('');
    const editorRef = useRef();
    const [image, setImage] = useState("");
    const inputRef = useRef(null);

    const onFormSubmit = (data) => {
        console.log(data);
        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("body", value);
        formData.append("image", image);
        formData.append("publicationDate", data.publicationDate);
        axiosService.post(`${BACKEND_URL}/api/blog/article`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(data => {
                if (data?.status === 201) {
                    toast.success(data?.message);
                    setOpen(false);
                    updateBlogs();
                }
            })
            .catch(error => {
                console.log(error);
                toast.error("Erreur servenu lors de l'ajout d'un article")
            });
    }

    const handleImageChange = (e) => {
        const files = e.target.files;
        console.log(files);
        setImage(files[0]);
    };

    const handleImageClick = () => {
        inputRef.current.click();
    }

    return (
        <>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                    <strong>{t('addBlogMsg')}</strong>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-col">
                                <label
                                    htmlFor="title"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    {t('title')}
                                </label>
                                <input
                                    name="title"
                                    type="text"
                                    id="title"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    {...register("title", {
                                        required: true
                                    })}
                                />
                                {errors.title && errors.title.type === "required" && (
                                    <p className="text-red-600">{t('titleErrMsg')}</p>
                                )}
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor="description"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    {t('description')}
                                </label>
                                <textarea
                                    name="description"
                                    type="text"
                                    id="description"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    {...register("description")}
                                />
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor="body"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    {t('content')}
                                </label>
                                <ReactQuill
                                    className="dark:text-white"
                                    theme="snow"
                                    value={value}
                                    onChange={(value) => {
                                        setValue(value);
                                    }}
                                    ref={editorRef}
                                />
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor="body"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    {t('image')}
                                </label>
                                <div onClick={handleImageClick} className="cursor-pointer border-dashed border-4 rounded-3xl border-gray-400">
                                    {
                                        image ?
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt="Blog Image Preview"
                                                className="rounded-3xl"
                                                width={1200}
                                                style={{ height: "250px" }}
                                            /> :
                                            <img
                                                src={defaultImg}
                                                alt="Blog Image Preview"
                                                className="rounded-3xl"
                                                width={1200}
                                                style={{ height: "250px" }}
                                            />
                                    }
                                    <input
                                        ref={inputRef}
                                        id="image"
                                        type="file"
                                        className="hidden"
                                        name="image"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor="publicationDate"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    {t('publicationDate')}
                                </label>
                                <input
                                    name="publicationDate"
                                    type="datetime-local"
                                    id="publicationDate"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    {...register("publicationDate", {
                                        required: true
                                    })}
                                />
                                {errors.publicationDate && errors.publicationDate.type === "required" && (
                                    <p className="text-red-600">{t('publicationDateErrMsg')}</p>
                                )}
                            </div>
                            <div className="flex flex-col">
                                <button
                                    type="submit"
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    {t('add')}
                                </button>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddBlogModal;